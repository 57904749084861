/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Writable } from 'stream';
import { Parser } from 'htmlparser2';

import PropTypes from "prop-types";
import "./index.scss"

/* global Word, require */

const STREAM_LIMIT = 5;
const App = ({ setRoute, setGlobalState, globalState }) => {

  useEffect(() => {
    const url = `${process.env.BASE_PATH}/api/v2/content_stream`;
    fetch(url, { method: 'POST' })
      .then(response => response.body.getReader())
      .then(reader => {
        const decoder = new TextDecoder();
        let buffer = '';
        const parser = new Parser({
          onopentag: () => {
            if (buffer.length > STREAM_LIMIT) {
              insertHtml(buffer);
              buffer = '';
            }
          },
          ontext: (text) => {
            buffer += text;
          },
          onclosetag: () => {
            buffer += '</' + parser.tagName + '>';
            if (buffer.length > STREAM_LIMIT) {
              insertHtml(buffer);
              buffer = '';
            }
          }
        });
  
        return reader.read().then(function processText({ done, value }) {
          if (done) {
            console.log("Stream complete");
            // Handle remaining buffer if there's any
            if (buffer.length > 0) {
              insertHtml(buffer);
            }
            return;
          }
          parser.write(decoder.decode(value));
          return reader.read().then(processText);
        });
      })
      .catch(error => console.error(error));
  }, []);


  const insertHtml = (html) => {
    console.log("HIT", html)
    Word.run(function (context) {
      var body = context.document.body;
      body.insertHtml(html, Word.InsertLocation.end);
      return context.sync();
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    });
  }


  const RenderBackButton = () => {
    return (
      <>
        <a href="javascript:(void)" className="back-button" onClick={() => setRoute('/summarise')}>Back</a>
      </>
    )
  }

  return (
    <div className="view-summary">
      <RenderBackButton />
      <h3 className="task-title">{globalState.taskTitle}</h3>
      <div className="summary-card">
        <p>{globalState.response}</p>
      </div>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;

