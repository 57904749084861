/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from "react";
import Header from "./../modules/Header"
import Login from "./components/Login";
import Blocker from "./components/Blocker";
import SelectTask from "./components/SelectTask";
import Review from "./components/Review";
import Transform from "./components/Transform";
import Assistant from "./components/Assistant";
import ViewSummary from "./components/ViewSummary";
import InputVariable from "./components/InputVariable";
import Generate from "./components/Generate";
import GenerationQuestionnaire from "./components/GenerationQuestionnaire"
import Query from "./components/Query"
import SelectGenerationMethod from "./components/SelectGenerationMethod"
import OneShotGeneration from "./components/OneShotGeneration"


import ContentStream from "./components/ContentStream";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global document, Office, module, require */


const Routing = () => {
  const [route, setRoute] = useState("/login");
  const [globalState, setGlobalState] = useState({});

  const changeRoute = (newRoute, shouldClearGlobalState = true) => {
    setRoute(newRoute);
    if (shouldClearGlobalState) {
      setGlobalState({})
    }
  }


  const AppView = () => {
    switch (route) {
      case "/login":
        return <Login setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      case "/blocker":
        return <Blocker setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      case "/select_task":
        return <SelectTask setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      case "/review":
        return <Review setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      case "/transform":
        return <Transform setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      case "/summarise":
        return <Assistant setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      case "/view_summary":
        return <ViewSummary setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/input_variable":
        return <InputVariable setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/content_stream":
        return <ContentStream setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/generate":
        return <Generate setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/generation_questionnaire":
        return <GenerationQuestionnaire setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/query":
        return <Query setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/select_generation_method":
        return <SelectGenerationMethod setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
        case "/one_shot_generation":
        return <OneShotGeneration setRoute={changeRoute} setGlobalState={setGlobalState} globalState={globalState} isOfficeInitialized={true} />;
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <React.Fragment>
      <Header currentRoute={route} setRoute={changeRoute} />
      <AppView />
    </React.Fragment>
  );
};

export default Routing;
