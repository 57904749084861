/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./index.scss";
import { Input } from "antd";
import Item from "antd/es/list/Item";
const { TextArea } = Input;

/* global Word, require */

const OneShotGeneration = ({ setRoute, isOfficeInitialized }) => {
  const [formData, setFormData] = useState([]);
  const [formComponents, setFormComponents] = useState();
  const [formResponses, setFormResponses] = useState([]);

  useEffect(async () => {
    await fetchFormData();
  }, []);

  useEffect(() => {
    if (formData.length) {
      let formComponentArray = [];
      formData.forEach((form) => {
        let component;
        if (form.question_type === "free_text") {
          component = RenderTextInput(form.question_body, form.id);
        } else if (form.question_type === "free_text_long") {
          component = RenderTextAreaInput(form.question_body, form.id);
        }
        formComponentArray.push(<div className="form-item" key={form.id}>{component}</div>);
      });
      setFormComponents(formComponentArray);
      // TODO: Set are Field filled
    }
  }, [formData]);

  const fetchFormData = async () => {
    const url = `${process.env.BASE_PATH}/api/v1/fetch_form`;
    const response = await axios.post(url, {});
    setFormData(response.data);
  };

  const RenderTextInput = (question_body, id) => {
    return (
      <>
        <span className="input-label">{question_body}</span>
        <Input
          className="form-text-input"
          placeholder="Enter response"
          onChange={(e) => handleFormFieldChange(e.target.value, question_body, id)}
        />
      </>
    );
  };
  const RenderTextAreaInput = (question_body, id) => {
    return (
      <>
      <span className="input-label">{question_body}</span>
      <TextArea
        className="form-textarea-input"
        rows={4}
        placeholder="Enter response"
        onChange={(e) => handleFormFieldChange(e.target.value, question_body, id)}
      /></>
    );
  };

  const handleFormFieldChange = (value, question_body, id) => {
    let e = formResponses;
    const existingResponseIndex = e.findIndex((response) => response.id === id);

    if (existingResponseIndex !== -1) {
      e[existingResponseIndex].response = value;
    } else {
      e.push({
        id: id,
        response: value,
      });
    }

    setFormResponses(e);
  };


  const handleFormSubmit = async () => {
    const requestObj = [];
    formData.forEach((question) => {
      const response = formResponses.find((response) => response.id === question.id);
      const e = {
        "response": response?.response || "NA",
        "key": question.key
      }
      requestObj.push(e);
    });
    const url = `${process.env.BASE_PATH}/api/v2/one_shot_generation`;
    const response = await axios.post(url, requestObj);
    console.log("RESPONSE", response.data);
    console.log("PROMPT", response.data.prompt);
  
    // Inserting the modified content at the start of the Word document
    Office.context.document.setSelectedDataAsync(response.data.document_content, { coercionType: 'text', start: 'start' }, (result) => {
      if (result.status === 'failed') {
        console.log('Failed to insert content:', result.error.message);
      }
    });
  };
  
  

  return (
    <div className="one-shot-generation-method-container">
      <div className="form-wrapper">{formComponents}</div>
      <div className="button-primary" onClick={handleFormSubmit}>
        Next
      </div>
    </div>
  );
};

// Blocker.propTypes = {
//   title: PropTypes.string,
//   isOfficeInitialized: PropTypes.bool,
// };

export default OneShotGeneration;
