/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import './index.scss';

/* global Word, require */


const Blocker = ({ setRoute, isOfficeInitialized }) => {
  const [selectedMethod, setSelectedMethod] = useState(0);


  const handleProceedClicked = () => {
    if(selectedMethod === 0) {
      setRoute("/one_shot_generation")
    } else {
      setRoute("/generate")
    }
  }

  return (
    <div className="select-generation-method-container">
        <h3>Select generation Method</h3>
        <div className="card-wrapper">
          <div className="card" onClick={() => setSelectedMethod(0)}>
            <h3>One Shot Generation</h3>
            <p>Fill a brief form to generate the agreement</p>
            <span className="time">&lt; 2 mins</span>
            <div className={`radio-button${selectedMethod === 0 ? ' selected': ''}`}></div>
          </div>

          <div className="card" onClick={() => setSelectedMethod(1)}>
            <h3>Questionnaire Generation</h3>
            <p>Answer specific questions to generate an agreement more suitable to your needs</p>
            <span className="time">3-5 mins</span>
            <div className={`radio-button${selectedMethod === 1 ? ' selected': ''}`}></div>
          </div>
        </div>


      <div className="bottom-button-container">
        <div className="button-primary large" onClick={handleProceedClicked}>Proceed</div>
      </div>

    </div>
  );
};

// Blocker.propTypes = {
//   title: PropTypes.string,
//   isOfficeInitialized: PropTypes.bool,
// };

export default Blocker;

