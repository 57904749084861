/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import './header.scss'


const Header = ({ currentRoute, setRoute }) => {

  const LoginHeader = () => {
    return (
      <div className="adidem-header-full">
        <div className="logo-full"></div>
        <div className="content-container">
          <h2>Drafting your contracts has never been simpler</h2>
          <p>The app is currently at capacity. If you don’t have an account, please join the waitlist</p>
        </div>
        <div className="action-container">
          <div className="button-primary">
            Join waitlist
          </div>
        </div>
      </div>
    )
  }


  const GenericHeader = () => {
    return (
      <div className="adidem-header-small">
        <div onClick={navigateHome} className="logo-monogram"></div>
      </div>
    )
  }

  const navigateHome = () => {
    setRoute('/select_task')
  }

  if(currentRoute === '/login') {
    return LoginHeader()
  } else {
    return GenericHeader()
  }
};

Header.propTypes = {
  currentRoute: PropTypes.string,
};

export default Header;

