/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./index.scss";
import { v4 as uuidv4 } from "uuid";

import ReviewDropdown from "../../../modules/ReviewDropdown";

/* global Word */

const App = ({ title, isOfficeInitialized }) => {
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [reviewSuggestions, setReviewSuggestions] = useState([]);

  const [isDocumentLoading, setIsDocumentLoading] = useState(true);

  const [isErrored, setIsErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    reviewDocument();
  }, []);

  const reviewDocument = () => {
    const startTime = new Date(); // Capture start time
    Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load('items');
      await context.sync();
      for (let p = 0; p < paragraphs.items.length; p++) {
        paragraphs.items[p].load(["text", "contentControls"]);
      }

      await context.sync();
      const BATCH_SIZE = 10;

      let uidCounter = 0;

      for (let p = 0; p < paragraphs.items.length; p += BATCH_SIZE) {
        for (let i = p; i < Math.min(p + BATCH_SIZE, paragraphs.items.length); i++) {
          const paragraph = paragraphs.items[i];

          if (paragraph.text.trim() === "") {
            continue;
          }

          // Insert a new content control anyways
          // if (hasContentControlWithUid(paragraph)) {
          //   continue;
          // }

          const range = paragraph.getRange("Whole");
          const contentControl = range.insertContentControl();
          contentControl.tag = `{%uid-${uidCounter}}`;
          contentControl.cannotEdit = false;
          contentControl.appearance = "Hidden";
          console.log("INSERT CONTENT CONTROL NOW", contentControl.tag);
          uidCounter++;
        }
        await context.sync();
      }

      let requestObjects = [];
      const htmlRequests = [];
      const uids = [];
      let uidRetrievalCounter = 0;

      for (let p = 0; p < paragraphs.items.length; p++) {
        const paragraph = paragraphs.items[p];
        const paragraphUid = `{%uid-${uidRetrievalCounter}}`;

        if (paragraph.text.trim() !== "") {
          console.log("GET HTML IN PARA");
          const range = paragraph.getRange("Whole");
          const htmlRequest = range.getHtml();
          htmlRequests.push(htmlRequest);
          uids.push(paragraphUid);
          uidRetrievalCounter++;
        }
      }

      const contentSyncStart = new Date();
      await context.sync();
      const contentSyncEnd = new Date();
      console.log("Execution Time for CONTENT SYNC: ", contentSyncEnd - contentSyncStart, "ms");

      const forLoopStart = new Date();
      for (let i = 0; i < htmlRequests.length; i++) {
        const paragraphHtml = htmlRequests[i].value;
        requestObjects.push({ uid: uids[i], html: paragraphHtml });
      }
      const forLoopEnd = new Date();
      console.log("Execution Time for FOR LOOP: ", forLoopEnd - forLoopStart, "ms");

      console.log("REQUEST OBJECTS", requestObjects);
      const endTime = new Date();
      console.log("Execution Time: ", endTime - startTime, "ms");

      setIsDocumentLoading(false);
      setIsApiLoading(true);

      const urlBase = `${process.env.BASE_PATH}/api/v1/`;
      const requestObjectsPayload = requestObjects;
      // const reviewIdentifyGapsPromise = axios.post(urlBase + 'review_identify_gaps', requestObjectsPayload);
      // const reviewMissingClausesPromise = axios.post(urlBase + 'review_missing_clauses', requestObjectsPayload);
      const reviewMissingDetailsPromise = axios.post(urlBase + 'review_missing_details', requestObjectsPayload);

      Promise.all([
        // reviewIdentifyGapsPromise,
        // reviewMissingClausesPromise,
        reviewMissingDetailsPromise
      ]).then((responses) => {
          responses.forEach((response) => {
            console.log("PROMPT", response.data.prompt)
          })
          const combinedData = responses.reduce((accumulatedData, response) => {
            return accumulatedData.concat(response.data.suggestions);
          }, []);

          setReviewSuggestions(combinedData);
          setIsApiLoading(false);
      }).catch(e => {
        setIsApiLoading(false);
        setIsErrored(true);
        console.log("ERROR", e);
      });
    });
  }

  return (
    <div className="review-container">
      {isErrored ? <div className="error-wrapper">
        <h5>An error has occured!</h5>
        <p>You can try again or contact support</p>
        <div className="retry-button" onClick={(e) => {
          setIsErrored(false);
          setErrorMessage("");
          reviewDocument();
        }}>Retry</div>
      </div> : <></>}
      {isDocumentLoading && !isApiLoading ? <h5>Processing document..</h5> : <></>}
      {!isDocumentLoading && isApiLoading ? <h5>Awaiting server response..</h5> : <></>}
      {(!isDocumentLoading && !isApiLoading && reviewSuggestions) ? <ReviewDropdown initialDropdownData={reviewSuggestions} /> : <></>}
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;
