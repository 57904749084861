/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import './index.scss';

/* global Word, require */


const Blocker = ({ setRoute, isOfficeInitialized }) => {

  const setupPayment = () => {
    setRoute('/select_task')
  }

  return (
    <div className="blocker-container">
      <div className="blocker-card">
        <h3>Setup your payment to continue using Adidem</h3>
      </div>

      <div className="button-primary" onClick={setupPayment}>
            Setup payment
          </div>
    </div>
  );
};

// Blocker.propTypes = {
//   title: PropTypes.string,
//   isOfficeInitialized: PropTypes.bool,
// };

export default Blocker;

