/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./index.scss"

/* global Word, require */


const App = ({ setRoute, setGlobalState, globalState }) => {

  useEffect(() => {
    console.log("LOADED", globalState)
  }, [])


  const RenderBackButton = () => {
    return (
      <>
        <a href="javscript:(void)" className="back-button" onClick={() => setRoute('/summarise')}>Back</a>
      </>
    )
  }

  return (
    <div className="view-summary">
      <RenderBackButton/>
      <h3 className="task-title">{globalState.taskTitle}</h3>
      <div className="summary-card">
        <div dangerouslySetInnerHTML={{__html: globalState.response.modified_content}}></div>
      </div>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;

