/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios"
import PropTypes from "prop-types";
import RenderAnswerInput from "../../../modules/FormInputs";
import "./index.scss";

/* global Word, require */

const RenderCurrentQuestion = ({
  currentStep,
  questionData,
  inputFreeText,
  inputFreeTextLong,
  inputSingleSelect,
  inputMultiSelect,
  inputNumber,
  setStepState,
  RenderNextStepButton
}) => {
  if (!questionData.length) return null;
  const { question_body, question_type, options, id } = questionData[currentStep - 1];
  return (
    <div className="question-card">
      <span className="question-text">{question_body}</span>
      <div className="answer-wrapper">
        <RenderAnswerInput
          currentStep={currentStep}
          type={question_type}
          options={options}
          inputFreeText={inputFreeText}
          inputFreeTextLong={inputFreeTextLong}
          inputSingleSelect={inputSingleSelect}
          inputMultiSelect={inputMultiSelect}
          inputNumber={inputNumber}
          setStepState={setStepState}
        />
        {RenderNextStepButton(question_type, id)}
      </div>
    </div>
  );
};

const App = ({ setRoute, setGlobalState, globalState }) => {

  const [currentQuestionnaireType, setCurrentQuestionnaireType] = useState('basic')
  const [answersToBasicQuestions, setAnswersToBasicQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [totalSteps, setTotalSteps] = useState(1);

  const [stepState, setStepState] = useState({
    inputFreeText: "",
    inputFreeTextLong: "",
    inputNumber: null,
    inputMultiSelect: [],
    inputSingleSelect: null,
    currentStep: 1,
  });

  useEffect(() => {
    if(globalState.from_step !== 'context') {
      setCurrentQuestionnaireType('detailed')
    }
    if(globalState?.questions) {
      let arr = [];
      globalState.questions.forEach((q) => {
        arr.push(...q);
      });
      setQuestionData(arr);
    } else {
      console.log("NO QUESTIONS")
    }
  }, [globalState])
  
  const addAnswer = useCallback((id, answerText) => {
    const updatedQuestions = [...questionData];
    const index = updatedQuestions.findIndex(question => question.id === id);
    if (index === -1) {
      console.error("Error: Id not found");
      return updatedQuestions;
    }
    updatedQuestions[index] = { ...updatedQuestions[index], answer: answerText };
    return updatedQuestions;
  }, [questionData]);

  const preparePayload = (input) => {
    const output = [];
    input.forEach(item => {
        let answer;
        if (item.question_type === 'single_select' || item.question_type === 'multi_select') {
            if(!item.answer || !Array.isArray(item.answer)) {
                answer = "NA"
            } else {
                answer = item.answer.length ? item.answer.map(index => item.options[index]).join(', ') : "NA";
            }
        } else {
            answer = item.answer || 'NA';
        }
        output.push({question: item.question_body, answer: answer});
    });
    return output;
}

  const RenderNextStepButton = (type, id) => {
    if (stepState.currentStep === totalSteps) {
      return (
        <>
        {currentQuestionnaireType == 'basic' ? <button className={`next-step-button finish${isLoading ? ' loading' : ''}`} onClick={() => handleGenerateClauseListClick(type, id)}>
          {isLoading ? 'Loading..' : 'Next Step'}
        </button> :  <button className={`next-step-button finish${isLoading ? ' loading' : ''}`} onClick={() => handleGenerateDocumentClick(type, id)}>
        {isLoading ? 'Loading..' : 'Generate Clause List'}
      </button> }
      </>
      );
    } else {
      return (
        <button className="next-step-button" onClick={() => handleNextStepClick(type, id)}>
          OK
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_271_440)">
              <path
                d="M11.8209 1.09985C11.1575 0.4592 10.6928 -0.618441 9.83478 0.467686C9.59247 0.726489 4.71462 5.94074 4.00359 6.70443C3.75335 6.44138 2.30349 4.88007 1.85066 4.40914C1.21114 3.81516 0.682839 4.91402 0.233981 5.29161C-0.0440731 5.60133 -0.0877673 5.92377 0.194259 6.22925C3.5309 9.81856 0.182342 6.24197 3.5309 9.81856C3.6739 9.97129 4.1744 10.1368 4.44848 9.83977C11.5825 2.21991 4.69873 9.57248 11.8328 1.94838C12.0671 1.69806 12.0513 1.35441 11.8209 1.09985Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_271_440">
                <rect width="12" height="10" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      );
    }
  };

  const handleNextStepClick = async (type, id) => {
    if(questionData[stepState.currentStep]?.answer) {
      // The user has already finished filling this step
      fillPreviousStep(stepState.currentStep + 1)
      return
    }
    let userAnswer;
    switch(type) {
      case "free_text":
        userAnswer = stepState.inputFreeText
        break;
      case "free_text_long":
        userAnswer = stepState.inputFreeTextLong
        break;
      case "multi_select":
        userAnswer = stepState.inputMultiSelect
        break;
      case "single_select":
        userAnswer = stepState.inputSingleSelect
        break;
      default:
        userAnswer = stepState.inputNumber
    }
    const updatedQuestionData = addAnswer(id, userAnswer);
    setQuestionData(updatedQuestionData)
    setStepState({
      inputFreeText: "",
      inputFreeTextLong: "",
      inputNumber: null,
      inputMultiSelect: null,
      currentStep: stepState.currentStep + 1
    });
  };

  const preGenerate = async (type, id) => {
    setIsLoading(true);
    let userAnswer;
    switch(type) {
      case "free_text":
        userAnswer = stepState.inputFreeText
        break;
      case "free_text_long":
        userAnswer = stepState.inputFreeTextLong
        break;
      case "multi_select":
        userAnswer = stepState.inputMultiSelect
        break;
      case "single_select":
        userAnswer = stepState.inputSingleSelect
        break;
      default:
        userAnswer = stepState.inputNumber
    }
    
    const updatedQuestionData = await addAnswer(id, userAnswer);
    setQuestionData(updatedQuestionData)
    if(currentQuestionnaireType === "basic") return preparePayload(updatedQuestionData)
    else {
      // Detailed questionnaire has both basic and detailed questions
      const currentPayload = preparePayload(updatedQuestionData);
      const completePayload = [...answersToBasicQuestions, ...currentPayload];
      return completePayload
    }
  }

  const handleGenerateClauseListClick = async (type, id) => {
    const basic_question_answers = await preGenerate(type, id);
    const payload = {
      "document_title": globalState?.document_title || 'NA', 
      "basic_question_answers": basic_question_answers, 
      "purpose": globalState.purpose,
      "representation": globalState.representation
    }
    try {
      setIsLoading(true);
      const url = `${process.env.BASE_PATH}/api/v1/detailed_questionnaire`;
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAnswersToBasicQuestions(basic_question_answers);
      setStepState({
        inputFreeText: "",
        inputFreeTextLong: "",
        inputNumber: null,
        inputMultiSelect: null,
        currentStep: 1,
      });
      setQuestionData(response.data);
      setTotalSteps(response.data.length)
      setCurrentQuestionnaireType("detailed")
      setIsLoading(false);
    } catch(e) {
      console.log("E", e)
    }

  }

  const handleGenerateDocumentClick = async (type, id) => {
    const question_answer_pairs = await preGenerate(type, id);
    let payload = {
      question_answer_pairs: question_answer_pairs,
      purpose: globalState.purpose,
      document_title: globalState.document_title,
      representation: globalState.representation
    }
    try {
      const url = `${process.env.BASE_PATH}/api/v1/clause_headings`;
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setGlobalState({
        is_questionnaire_filled: true,
        question_answer_pairs: question_answer_pairs,
        purpose: globalState.purpose,
        document_title: globalState.document_title,
        representation: globalState.representation,
        clause_list: response.data
      });
      setRoute("/generate", false)
    } catch (error) {
      console.log(error);
      // customDebugger(error);
    }
  }

  const fillPreviousStep = (step) => {
    const answerText = questionData[step-1]?.answer;
    const questionType = questionData[step-1]?.question_type;
    switch(questionType) {
      case "free_text":
        setStepState({
          ...stepState,
          inputFreeText: answerText,
          currentStep: step,
        });
        break;
      case "free_text_long":
        setStepState({
          ...stepState,
          inputFreeTextLong: answerText,
          currentStep: step,
        });
        break;
      case "free_number":
        setStepState({
          ...stepState,
          inputNumber: answerText,
          currentStep: step,
        });
        break;
      case "single_select":
        setStepState({
          ...stepState,
          inputSingleSelect: answerText,
          currentStep: step,
        });
        break;
      case "multi_select":
        setStepState({
          ...stepState,
          inputMultiSelect: answerText,
          currentStep: step,
        });
        break;
      default: 
        break;
    }
  }
  const handlePreviousStepClick = () => {
    let updatedStep = stepState.currentStep - 1;
    fillPreviousStep(updatedStep);
  }
  const RenderStepCount = () => {
    let stepCount = questionData.length;
    setTotalSteps(stepCount);
    return (
      <>
        {stepState.currentStep}/{stepCount}
      </>
    );
  };

  const RenderProgressBar = () => {
    const progress = ((stepState.currentStep - 1) / totalSteps) * 100;
    return (
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
    );
  };

  const RenderBackButton = () => {
    return (
      <div className={`previous-step${stepState.currentStep === 1 ? ' disabled' : ''}`}>
        <a href="javascript:(void)" className="back-button" onClick={handlePreviousStepClick}>
          Previous Step
        </a>
      </div>
    );
  };

  return (
    <div className="generation-questionnaire">
      <div className="header-section">
        <RenderBackButton />
        <RenderStepCount />
      </div>
      <RenderProgressBar />
      <div className="summary-card">
        <RenderCurrentQuestion 
          currentStep={stepState.currentStep}
          questionData={questionData}
          inputFreeText={stepState.inputFreeText}
          inputFreeTextLong={stepState.inputFreeTextLong}
          inputSingleSelect={stepState.inputSingleSelect}
          inputMultiSelect={stepState.inputMultiSelect}
          inputNumber={stepState.inputNumber}
          setStepState={setStepState}
          RenderNextStepButton={RenderNextStepButton}
        />
      </div>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;
