/* eslint-disable prettier/prettier */
import axios from "axios";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Progress from "./../../../modules/Progress";
import { setCookie, getCookie } from "../../../helpers/cookies";
// import SigninwithGoogle from "./../../../modules/SigninwithGoogle";
import streamEvent from "./../../../helpers/event-stream"

import { dialogFallback } from "./../../../modules/FallbackAuthDialog";
import './index.scss'

/* global Word, require */

const App = ({ setRoute, setState, state, isOfficeInitialized }) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  useEffect(() => {
    // If auth token exists, redirect. Individual API calls are being authentication so there's no need to check the auth token's validity here
    const auth_cookie = getCookie('adidem-auth-token');
    if(auth_cookie?.length) {
      axios.defaults.headers.common['auth-token'] = auth_cookie;
      setRoute('/select_task')
    }
  }, []);

  useEffect(() => {
    // Product Analytics
    streamEvent({
      event_name: 'Login viewed',
      type: 'interaction'
    });
  }, []);

  const getMSAccessToken = async (callback) => {
      try {
        let middletierToken = await Office.auth.getAccessToken({
          allowSignInPrompt: true,
          allowConsentPrompt: true,
          forMSGraphAccess: true,
        });
        let response = await getUserData(middletierToken);
        if (!response) {
          throw new Error("Middle tier didn't respond");
        } else if (response.access_token) {
          console.log("GOT RESPONSE", response)
        }
        if (response.error) {
          console.log(response);
        } else {
          callback(response);
        }
      } catch (exception) {
        if (exception.code) {
          dialogFallback(callback);
        } else {
          console.log("EXCEPTION: " + JSON.stringify(exception));
          throw exception;
        }
        streamEvent({
          event_name: 'Login errored',
          type: 'error',
          description: JSON.stringify(exception)
        });
      }
  };

  const getUserData = async (token) => {
  try {
    const response = await axios.post(`${process.env.BASE_PATH}/api/v1/login`, {
      token: token,
      provider: 'microsoft'
    });
    return response.data;
  } catch (error) {
    console.log("Error in getFromApi function:", error);
  }
  }

  
  const handleLoginClick = async (provider) => {
    setIsLoginLoading(true)
    if (provider === "microsoft") {
      try {
        const token = await getMSAccessToken(handleSuccessfulLogin);
      } catch (error) {
        console.log("Error getting MS token:", error);
      }
    }
  };

  const handleSuccessfulLogin = (token) => {
    const {access_token} = token;
    axios.defaults.headers.common['auth-token'] = access_token;
    setCookie('adidem-auth-token', access_token, 30)
    setIsLoginLoading(false)
    setRoute("/select_task")
  }

  if (!isOfficeInitialized) {
    return (
      <Progress
        title={'LOADING'}
        message="Loading.."
      />
    );
  }

  return (
    <div className="login-wrapper">
      {/* <div className="login-button" onClick={() => handleLoginClick('google')}>
        Sign in with Google
      </div> */}
      {/* <SigninwithGoogle></SigninwithGoogle> */}
      <div className={`login-button ${isLoginLoading ? 'loading' : ''}`} onClick={() => handleLoginClick('microsoft')}>
        Sign in with Microsoft
      </div>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;

