/* eslint-disable prettier/prettier */
import React, {useEffect} from "react";
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import './taskpane.scss';
import AppWrapper from './../modules/AppWrapper.js';
import { setCookie } from "../helpers/cookies";

/* global document, Office, module, require */

Sentry.init({
  dsn: "https://a85c1c17105e44b890c79139526f7e45@o4505431294345216.ingest.sentry.io/4505431296442368",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/adidem\.ai/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.3, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

initializeIcons();

let isOfficeInitialized = false;
const title = 'Adidem';

const render = (Component) => {

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const setSessionID = () => {
    const sessionID = generateUUID();
    setCookie("session-id", sessionID, null)
  }
  setSessionID();

  ReactDOM.render(
    <AppContainer>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} />
    </AppContainer>,
    document.getElementById('container')
  );
};

Office.onReady(() => {
  isOfficeInitialized = true;
  render(AppWrapper);
});

if (module.hot) {
  module.hot.accept('./../modules/AppWrapper.js', () => {
    const NextApp = require('./../modules/AppWrapper.js').default;
    render(NextApp);
  });
}