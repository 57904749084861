import React from "react";
import "./loader.scss";

const Loader = () => {
  const circleStyle1 = {
    animationPlayState: "running",
    animationDelay: "0s",
  };

  const circleStyle2 = {
    animationPlayState: "running",
    animationDelay: "0s",
  };

  return (
    <div className="loader-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: "auto",
          background: "none",
          display: "block",
          shapeRendering: "auto",
          animationPlayState: "running",
          animationDelay: "0s",
        }}
        width="257px"
        height="257px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="50" cy="50" r="0" fill="none" stroke="#004732" strokeWidth="3" style={circleStyle1}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.639344262295082s"
            values="0;19"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="0s"
            style={circleStyle1}
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1.639344262295082s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="0s"
            style={circleStyle1}
          ></animate>
        </circle>
        <circle cx="50" cy="50" r="0" fill="none" stroke="#c9ff94" strokeWidth="3" style={circleStyle2}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.639344262295082s"
            values="0;19"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="-0.819672131147541s"
            style={circleStyle2}
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1.639344262295082s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="-0.819672131147541s"
            style={circleStyle2}
          ></animate>
        </circle>
      </svg>
      <span className="loader-text">Generating output</span>
    </div>
  );
};

export default Loader;
