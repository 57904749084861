export const pollTextSelection = async (setIsTextSelected) => {
  try {
    const context = new Word.RequestContext();
    const selection = context.document.getSelection();
    selection.load("text");
    await context.sync();

    // eslint-disable-next-line office-addins/load-object-before-read
    if (selection.text.length > 0) {
      setIsTextSelected(true);
    } else {
      setIsTextSelected(false);
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

export const getSelectedText = async () => {
  return new Promise((resolve, reject) => {
    Word.run(async (context) => {
      const selection = context.document.getSelection();
      // eslint-disable-next-line office-addins/no-navigational-load
      selection.load("text, font, style");
      await context.sync();
      const selectedText = selection.text;
      resolve(selectedText);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const getSelectedTextWithFormatting = async () => {
  return new Promise((resolve, reject) => {
    Word.run(async (context) => {
      const selection = context.document.getSelection();
      // eslint-disable-next-line office-addins/no-navigational-load
      selection.load("text, font, style");
      await context.sync();
      const formattedText = {
        text: selection.text,
        font: selection.font,
        style: selection.style,
      };
      resolve(formattedText);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const convertToHtml = (formattedText) => {
  const { text, font, style } = formattedText;
  let html = `<p style="font-size: ${font.size}pt; font-weight: ${style.bold ? "bold" : "normal"};">${text}</p>`;
  return html;
};

export const convertHtmlToOoxml = async (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const plainText = doc.body.textContent || "";
  return plainText;
};

export const replaceSelectedTextWithOoxml = async (plainText) => {
  return new Promise((resolve, reject) => {
    window.Word.run(async (context) => {
      // Set the change tracking mode to track all changes
      context.document.changeTrackingMode = window.Word.ChangeTrackingMode.trackAll;
      await context.sync();

      const selection = context.document.getSelection();
      selection.insertText(plainText, "Replace"); // Use insertText instead of insertOoxml
      await context.sync();

      const before = selection.getReviewedText(window.Word.ChangeTrackingVersion.original);
      const after = selection.getReviewedText(window.Word.ChangeTrackingVersion.current);
      await context.sync();

      console.log(`Reviewed text (before):\n${before.value}`);
      console.log(`Reviewed text (after):\n${after.value}`);

      resolve();
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
};
