/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";

const chatDefinitions = [
  {
    id: "ask_document_title",
    initiator: "system",
    body: "Please mention the title of the document you would like to generate today.",
    metadata: {}
  },{
    id: "answer_for_document_title",
    initiator: "user",
    body: "",
    metadata: {}
  },{
    id: "ask_document_purpose",
    initiator: "system",
    body: "Mention the purpose of ${answer_for_document_title}",
    metadata: {}
  },{
    id: "answer_for_document_purpose",
    initiator: "user",
    body: "",
    metadata: {}
  },
]

const Generate = () => {
  return (
    <></>
  )
}

export default Generate