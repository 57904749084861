/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import './index.scss'
import streamEvent from "../../../helpers/event-stream";
import { pollTextSelection, getSelectedTextWithFormatting, convertToHtml, convertHtmlToOoxml, replaceSelectedTextWithOoxml } from "../../../helpers/contentHandler";
import Loader from "./../../../modules/Loader"
import { Input } from "antd";

const { TextArea } = Input;

/* global Word, require */

const App = ({ setRoute, setGlobalState, globalState }) => {
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState('summarise');
  const [currentlySelectedTask, setCurrentlySelectedTask] = useState('')
  const [currentlySelectedTaskData, setCurrentlySelectedTaskData] = useState('')
  const [tabRenderElement, setTabRenderElement] = useState();
  const [tabContentRenderElement, setTabContentRenderElement] = useState()
  const [availableMethods, setAvailableMethods] = useState([]);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTabType, setSelectedTabType] = useState([]);

  // Fetch the available methods and the relevant prompts
  useEffect(async () => {
    const method_data = await fetchAvailableTasks();
    setAvailableMethods(method_data)
    // Select the first method from first type by default
    setCurrentlySelectedTask(method_data[0].methods[0].id)
    setCurrentlySelectedTaskData(method_data[0].methods[0])
  }, [])


  useEffect(() => {
    const pollInterval = 500; // Polling interval in milliseconds
    const intervalId = setInterval(() => {pollTextSelection(setIsTextSelected)}, pollInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(availableMethods).length) {
      let tabContent = [];
      let selectedTabData = availableMethods.find((item) => {
        return item.type == currentlySelectedTab
      });
      setSelectedTabType(selectedTabData.type)
      selectedTabData.methods.forEach((method) => {
        tabContent.push(
          <div className={`method-card ${currentlySelectedTask == method.id ? 'selected' : ''}`} onClick={() => selectTask(method.id)}>
            <h4 className="method-title">{method.title}</h4>
            <p className="method-description">
              {method.description}
            </p>
          </div>
        )
      })
      setTabContentRenderElement(<div className="tab-content-container">
        {tabContent}
      </div>)

      let tabData = [];
      availableMethods.forEach(tab => {
        tabData.push(<div className={currentlySelectedTab === tab.type ? 'tab-item selected' : 'tab-item'} onClick={() => setCurrentlySelectedTab(tab.type)}>
          <span>{tab.type}</span>
        </div>)
      });
      setTabRenderElement(
        <div className="tab-container">
          {tabData}
        </div>
      )
    }


  }, [availableMethods, currentlySelectedTab, currentlySelectedTask])

  const fetchAvailableTasks = async () => {
    const url = `${process.env.BASE_PATH}/api/v1/assistant_methods`;
    try {
      const response = await axios.post(url);
      return response.data;
    } catch (error) {
      console.error("Error in getFromApi function:", error);
    }
  }
  
  const sendToApi = async (formattedHtml, shouldFormatForDisplay = false) => {
    const selectedTask = availableMethods.find(task => task.type == currentlySelectedTab)
    const task = selectedTask.methods.find(method => method.id == currentlySelectedTask)
    const url = `${process.env.BASE_PATH}/api/v1/assistant_task`;
    const data = { content: formattedHtml, prompt: task.prompt, should_format_for_display: shouldFormatForDisplay };
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      // customDebugger(error);
    }
  };

  const proceedClicked = async () => {
    try {
      setIsLoading(true);
      const methodType = currentlySelectedTaskData.type;

      // Text modification without any variables 
      if (methodType.includes('text_modification') && !methodType.includes('variable_input')) {
        const formattedText = await getSelectedTextWithFormatting();
        const selectedTextHtml = convertToHtml(formattedText);
        const responseData = await sendToApi(selectedTextHtml);

        // Check if the responseData has the modified HTML content
        if (responseData) {
          const ooxml = await convertHtmlToOoxml(responseData);
          await replaceSelectedTextWithOoxml(ooxml);
          setIsLoading(false)
        }
      }
      else if(methodType.includes('text_modification') && methodType.includes('variable_input')) {
        const formattedText = await getSelectedTextWithFormatting();
        const selectedTextHtml = convertToHtml(formattedText);
        setGlobalState({
          selectedContent: selectedTextHtml,
          taskTitle: currentlySelectedTaskData.title,
          inputPlaceholder: currentlySelectedTaskData.input_placeholder,
          prompt: currentlySelectedTaskData.prompt,
          preprompt_question: currentlySelectedTaskData.preprompt_question || "",
          preprompt_answer: currentlySelectedTaskData.preprompt_answer || ""
        });
        setRoute("/input_variable", false)
      }
       else {
        const formattedText = await getSelectedTextWithFormatting();
        const selectedTextHtml = convertToHtml(formattedText);
        const responseData = await sendToApi(selectedTextHtml, true);
        console.log("currentlySelectedTaskData", currentlySelectedTaskData)
        // Check if the responseData has the modified HTML content
        if (responseData) {
          setGlobalState({
            response: responseData,
            taskTitle: currentlySelectedTaskData.title
          });
          setRoute("/view_summary", false)
        }
      }
    } catch (error) {
      console.log("5" + error);
    }
  }

  const selectTask = (method_id) => {
    let e = availableMethods.find((item) => item.type === currentlySelectedTab);
    let i = e.methods.find((method) => method.id === method_id)
    setCurrentlySelectedTaskData(i)

    setCurrentlySelectedTask(method_id);
    streamEvent({
      event_name: 'Task selected',
      selected_task: method_id,
      type: 'interaction'
    })
  }


  const RenderBackButton = () => {
    return (
      <>
        <a href="javascript:(void)" className="back-button" onClick={() => setRoute('/select_task')}>Back</a>
      </>
    )
  }

  const RenderProceedButton = () => {
    return (
      <div className="bottom-button-container" onClick={proceedClicked}>
        <div className={`button-primary large ${isTextSelected ? '' : 'disabled'}`}>Proceed</div>
      </div>
    )
  }

  return (
    <div className="ms-welcome assistant-page">
      {!isLoading ? <div style={{ width: "calc(100vw - 48px)", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <RenderBackButton></RenderBackButton>
        {tabRenderElement}
        {tabContentRenderElement}
        <RenderProceedButton></RenderProceedButton>
      </div> :
        <Loader/>
      }
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;

