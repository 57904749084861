/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { Input } from "antd";
const { TextArea } = Input;

function RenderAnswerInput({
  currentStep,
  type,
  options = [],
  inputFreeText,
  inputFreeTextLong,
  inputSingleSelect,
  inputMultiSelect,
  inputNumber,
  setStepState,
}) {
  // Local state for free_text input
  const [localFreeText, setLocalFreeText] = useState(inputFreeText);
  const [localFreeNumber, setLocalFreeNumber] = useState(inputNumber);
  const [localFreeTextLong, setLocalFreeTextLong] = useState(inputFreeTextLong);

  const [localMultiSelect, setLocalMultiSelect] = useState(null);
  const [localSingleSelect, setLocalSingleSelect] = useState(null);

  useEffect(() => {
    // Reset after every change
    setLocalFreeText(inputFreeText);
    setLocalFreeTextLong(inputFreeTextLong);
    setLocalFreeNumber(inputNumber);
    setLocalMultiSelect(inputMultiSelect);
    setLocalSingleSelect(inputSingleSelect);
  }, [currentStep]);

  // Update parent state when localFreeText changes
  useEffect(() => {
    setStepState({
      inputFreeText: localFreeText,
      inputFreeTextLong: "",
      inputNumber: null,
      inputMultiSelect: null,
      inputSingleSelect: null,
      currentStep: currentStep,
    });
  }, [localFreeText, setStepState]);

  useEffect(() => {
    setStepState({
      inputFreeText: "",
      inputFreeTextLong: localFreeTextLong,
      inputNumber: null,
      inputMultiSelect: null,
      inputSingleSelect: null,
      currentStep: currentStep,
    });
  }, [localFreeTextLong, setStepState]);

  useEffect(() => {
    setStepState({
      inputFreeText: "",
      inputFreeTextLong: "",
      inputNumber: localFreeNumber,
      inputMultiSelect: null,
      inputSingleSelect: null,
      currentStep: currentStep,
    });
  }, [localFreeNumber, setStepState]);

  useEffect(() => {
    setStepState({
      inputFreeText: "",
      inputFreeTextLong: "",
      inputNumber: null,
      inputMultiSelect: localMultiSelect,
      inputSingleSelect: null,
      currentStep: currentStep,
    });
  }, [localMultiSelect, setStepState]);

  useEffect(() => {
    setStepState({
      inputFreeText: "",
      inputFreeTextLong: "",
      inputNumber: null,
      inputMultiSelect: null,
      inputSingleSelect: localSingleSelect,
      currentStep: currentStep,
    });
  }, [localSingleSelect, setStepState]);

  const freeTextElement = useMemo(() => {
    return (
      <Input
        className={`questionnaire--${type}`}
        placeholder="Type your answer"
        value={localFreeText}
        onChange={(e) => setLocalFreeText(e.target.value)}
      ></Input>
    );
  }, [type, localFreeText]);

  const freeTextLongElement = useMemo(() => {
    return (
      <TextArea
        className={`questionnaire--${type}`}
        placeholder="Type your answer"
        value={localFreeTextLong}
        onChange={(e) => setLocalFreeTextLong(e.target.value)}
      ></TextArea>
    );
  }, [type, localFreeTextLong]);

  const multiSelectElements = useMemo(() => {
    return options.map((option, i) => (
      <div
        className={`questionnaire--${type} ${inputMultiSelect && inputMultiSelect.includes(i) ? "selected" : ""}`}
        key={i}
        onClick={() => {
          if (inputMultiSelect && inputMultiSelect.includes(i)) {
            setLocalMultiSelect(inputMultiSelect.filter((index) => index !== i));
          } else {
            setLocalMultiSelect([...(inputMultiSelect || []), i]);
          }
        }}
      >
        {option}
      </div>
    ));
  }, [type, options, inputMultiSelect, setLocalMultiSelect]);

  const singleSelectElements = useMemo(() => {
    return options.map((option, i) => (
      <div
        className={`questionnaire--${type} ${inputSingleSelect && inputSingleSelect.includes(i) ? "selected" : ""}`}
        key={i}
        onClick={() => {
          setLocalSingleSelect([i]);
        }}
      >
        {option}
      </div>
    ));
  }, [type, options, localSingleSelect, setLocalSingleSelect]);

  const numberCurrencyElement = useMemo(() => {
    return (
      <Input
        className={`questionnaire--${type}`}
        value={localFreeNumber}
        addonBefore="₹"
        type="number"
        placeholder="Type your answer"
        onChange={(e) => setLocalFreeNumber(e.target.value)}
      ></Input>
    );
  }, [type, localFreeNumber]);

  const numberElement = useMemo(() => {
    return (
      <Input
        className={`questionnaire--${type}`}
        type="number"
        placeholder="Type your answer"
        value={localFreeNumber}
        onChange={(e) => setLocalFreeNumber(e.target.value)}
      ></Input>
    );
  }, [type, localFreeNumber]);

  if (type === "free_text") {
    return freeTextElement;
  } else if (type === "free_text_long") {
    return freeTextLongElement;
  } else if (type === "multi_select") {
    return <>{multiSelectElements}</>;
  } else if (type === "single_select") {
    return <>{singleSelectElements}</>;
  } else if (type === "number_currency") {
    return <>{numberCurrencyElement}</>;
  } else {
    return <>{numberElement}</>;
  }
}

export default RenderAnswerInput;
