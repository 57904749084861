/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* global Word, require */


const App = ({ title, isOfficeInitialized }) => {



  return (
    <div className="ms-welcome">
      {/* <Header logo={require("./../../../assets/logo-filled.png")} title={title} message="Welcome" /> */}
        <h1>Hello, select a task</h1>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;

