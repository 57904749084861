import React, { useState, useEffect } from "react";
import Routes from "./../taskpane/Routes";
import OfflineScreen from "../modules/OfflineScreen";
import axios from "axios"

const AppWrapper = ({ title, isOfficeInitialized }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    axios.defaults.timeout = 500000;
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return isOnline ? <Routes title={title} isOfficeInitialized={isOfficeInitialized} /> : <OfflineScreen />;
};

export default AppWrapper;
