import React from "react";
import "./offlinescreen.scss";

const OfflineScreen = () => {
  return (
    <div className="offline-screen">
      <h1>You are offline</h1>
      <p>Please check your internet connection and try again.</p>
    </div>
  );
};

export default OfflineScreen;
