/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import './review-dropdown.scss'

const ReviewDropdown = ({ initialDropdownData }) => {
  const [dropdownData, setDropdownData] = useState(initialDropdownData);
  const [expandedReviews, setExpandedReviews] = useState([]);

  if (!dropdownData?.length) return null;

  const toggleDropdown = (index) => {
    if (expandedReviews.includes(index)) {
      setExpandedReviews(expandedReviews.filter(i => i !== index)); // Remove index from array if it's already included
    } else {
      setExpandedReviews([...expandedReviews, index]); // Add index to array if it's not included
      selectContentControl(dropdownData[index]); // Select the text in the content control
    }
  };

  const applyReviewChange = async (review) => {
    await Word.run(async (context) => {
      const contentControls = context.document.contentControls;
      contentControls.load('tag');
  
      const startContentControl = contentControls.getByTag(`{%uid-${review.metadata.start_UID}}`).getFirstOrNullObject();
      const endContentControl = contentControls.getByTag(`{%uid-${review.metadata.end_UID}}`).getFirstOrNullObject();
  
      const range = startContentControl.getRange(Word.RangeLocation.start).expandTo(endContentControl.getRange(Word.RangeLocation.end));
      range.load('text');
  
      await context.sync();
  
      if (!startContentControl.isNullObject && !endContentControl.isNullObject) {
        const payload = {
          "document_snippet": range.text,
          "suggesion_title": review.title,
          "suggestion_description": review.description,
          "suggestion_instruction": review.metadata.instruction
        }
        const url = `${process.env.BASE_PATH}/api/v1/apply_review`;
        const response = await axios.post(url, payload);
        console.log("PROMPT", response.data.prompt)
        insertSuggestionText(response.data.updated_snippet, startContentControl, endContentControl)
      } else {
        console.log("Could not find content controls");
        printContentControls(review.metadata, startContentControl, endContentControl);
      }
    });
  };


  const insertSuggestionText = async (document_text, startContentControl, endContentControl) => {
    await Word.run(async (context) => {
      const range = startContentControl.getRange(Word.RangeLocation.start).expandTo(endContentControl.getRange(Word.RangeLocation.end));
      range.select();
      await context.sync();

      if (!startContentControl.isNullObject && !endContentControl.isNullObject) {
        const selection = context.document.getSelection();
        selection.insertText(document_text, Word.InsertLocation.replace);
        await context.sync();
        console.log("Text replaced successfully");
      } else {
        console.log("Could not find content controls");
      }
    });
  };

  const removeSuggestionFromList = (review_id) => {
    const newDropdownData = dropdownData.filter(review => review.id !== review_id);
    // Assuming dropdownData is a state or prop, you would set it here.
    setDropdownData(newDropdownData); // if using state
  };

  const selectContentControl = async (review) => {
    await Word.run(async (context) => {
      if (review.suggestion_type === 'modification') {
        const startUID = review.metadata.start_UID;
        const endUID = review.metadata.end_UID;
  
        let startRange = null;
        let endRange = null;
  
        for (let uid = startUID; uid <= endUID; uid++) {
          const contentControl = context.document.contentControls.getByTag(`{%uid-${uid}}`).getFirstOrNullObject();
          contentControl.load('text');
  
          await context.sync();
  
          if (contentControl.isNullObject) {
            console.log(`Content control with UID ${uid} is null.`);
          } else {
            if (uid === startUID) startRange = contentControl.getRange('Start');
            if (uid === endUID) endRange = contentControl.getRange('End');
          }
        }
  
        if (startRange && endRange) {
          const range = startRange.expandTo(endRange);
          range.select('Select');
        }
      } else if (review.suggestion_type === 'addition') {
        const contentControl = context.document.contentControls.getByTag(`{%uid-${review.metadata.start_UID}}`).getFirstOrNullObject();
        contentControl.load('text');
  
        await context.sync();
  
        if (!contentControl.isNullObject) {
          contentControl.getRange('Start').select('Select');
        }
      }
    });
  };

  const printContentControls = (metadata) => {
    console.log("FINDING CONTENT CONTROL", metadata)
    Word.run(function (context) {
      var contentControls = context.document.contentControls;
      context.load(contentControls, 'tag, title');
      return context.sync().then(function () {
          console.log('Found ' + contentControls.items.length + ' content controls in the document.');
          for (var i = 0; i < contentControls.items.length; i++) {
              var item = contentControls.items[i];
              console.log('Content Control: Title: ' + item.title + ', Tag: ' + item.tag);
          }
      });
    })
    .catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
          console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }

  return (
    <div className="review-wrapper">
      {dropdownData.map((item, index) => (
        <div className="review-card" key={index} onClick={() => toggleDropdown(index)}>
          <h3>{item.title}</h3>
          <div className={`content ${expandedReviews.includes(index) ? 'expanded' : ''}`}>
            <p>{item.description}</p>
            <div className={`button-secondary`} onClick={() => applyReviewChange(item)}>Apply</div>
            {/* <div className={`button-hollow`} onClick={() => hideReviewItem(item)}>Hide</div> */}
          </div>
        </div>
      ))}
    </div>
  )
};

ReviewDropdown.propTypes = {
  initialDropdownData: PropTypes.array,
};

export default ReviewDropdown;
