/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./index.scss"
import Loader from "./../../../modules/Loader"

import { pollTextSelection, getSelectedTextWithFormatting, convertToHtml, convertHtmlToOoxml, replaceSelectedTextWithOoxml } from "../../../helpers/contentHandler";

import { Input } from "antd";

/* global Word, require */


const App = ({ setRoute, setGlobalState, globalState }) => {
  const [userInput, setUserInput] = useState('');
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const pollInterval = 500; // Polling interval in milliseconds
    const intervalId = setInterval(() => {pollTextSelection(setIsTextSelected)}, pollInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const sendToApi = async (formattedHtml) => {
    const url = `${process.env.BASE_PATH}/api/v1/assistant_task`;
    const { prompt, preprompt_question, preprompt_answer} = globalState;
    const finalPrompt = prompt.replace("%variable%", userInput);
    
    const data = { content: formattedHtml, prompt: finalPrompt, preprompt_question: preprompt_question || "", preprompt_answer: preprompt_answer || "" };
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      // customDebugger(error);
    }
  };

  const RenderBackButton = () => {
    return (
      <>
        <a href="javascript:(void)" className="back-button" onClick={() => setRoute('/summarise')}>Back</a>
      </>
    )
  }

  const proceedClicked = async () => {
    setIsLoading(true);
    const formattedText = await getSelectedTextWithFormatting();
    const selectedTextHtml = convertToHtml(formattedText);
    const responseData = await sendToApi(selectedTextHtml);
    console.log("RESPONSE DATA", responseData)

    // Check if the responseData has the modified HTML content
    if (responseData) {
      const ooxml = await convertHtmlToOoxml(responseData.modified_content);
      console.log("RESPONSE ooxml", ooxml)
      await replaceSelectedTextWithOoxml(ooxml);
      setIsLoading(false);
    }
  }

  return (
    <div className="input-variable">
      {!isLoading ? <>
      <RenderBackButton />
      <h3 className="task-title">{globalState.taskTitle}</h3>
      <div className="summary-card">
        <Input placeholder={globalState.inputPlaceholder} onChange={(e) => setUserInput(e.target.value)} />
      </div>
      <div className="bottom-button-container" onClick={proceedClicked}>
        <div className={`button-primary large ${isTextSelected ? '' : 'disabled'}`}>Proceed</div>
      </div> 
      </>: <Loader/>}
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};

export default App;

